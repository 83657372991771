import React, { Fragment } from 'react';
import { Flex, Icon, Button, Text } from '@chakra-ui/react';
import { MdClose, MdDone } from 'react-icons/md';

const PurchaseCompleted = ({ onSubmit, onClose }) => {
  return (
    <Fragment>
      <Icon as={MdClose} onClick={onClose} left="20px" cursor="pointer" fontSize="25px" m="23px 29px" />
      <Flex alignItems="center" justifyContent="center" flexDirection="column" pt="70px">
        <Flex bg="#deeefa" p="8px 7px 7px 8px" w="90px" h="90px" borderRadius="50%" justifyContent="center">
          <Icon as={MdDone} fontSize="55px" color="#2085FF" alignSelf="center" />
        </Flex>
        <Text fontSize="16px" fontWeight="bold" mt="20px">
          Purchase completed
        </Text>
        <Text mt="10px" mb="40px" fontSize="12px" px="50px" textAlign="center" fontWeight="500">
          You are now the owner of this NFT. The NFT will show up in your profile shortly. This process can take up to
          30 minutes.
        </Text>
      </Flex>
      <Flex py="15px" px="35px" justifyContent="center">
        <Button variant="black" w="100%" onClick={onSubmit}>
          Continue
        </Button>
      </Flex>
    </Fragment>
  );
};
export default PurchaseCompleted;
