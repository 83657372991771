import React from 'react';
import axios from 'axios';
import notification from 'components/Notifications';
import { deviceType } from 'react-device-detect';
import { Flex, Heading, Button, Text, Avatar, Image } from '@chakra-ui/react';
import { authenticate, userSession, validateAuth } from 'utils/stacks';
import { decryptECIES, signECDSA } from '@stacks/encryption';
import useUser from 'lib/useUser';
import { API } from 'config/api';

const SuccessLink = ({ onSubmit }) => {
  // console.log('access_token', access_token);
  // const [loading, setLoading] = React.useState(false);
  const [cargando, setCargando] = React.useState(false);
  const [device, setDevice] = React.useState('');

  const { user, mutateUser } = useUser();

  React.useEffect(() => {
    setDevice(deviceType);
  }, [setDevice]);

  const onLoginWallet = async () => {
    setCargando(true);
    authenticate(async () => {
      let config = { headers: { Authorization: `Bearer ${user?.access_token}` } };
      let userData = userSession.loadUserData();
      const appsMeta = userData.profile.appsMeta;
      const address =
        process.env.NEXT_PUBLIC_ENV === 'production'
          ? userData.profile.stxAddress.mainnet
          : userData.profile.stxAddress.testnet;
      const private_key = userData.appPrivateKey;
      const appUrl = process.env.NEXT_PUBLIC_ENV === 'dev' ? 'http://localhost:3000' : process.env.NEXT_PUBLIC_URL;

      const public_key = appsMeta[appUrl].publicKey;

      API.post('/auth/login/wallet/initiate', { address, public_key }, config)
        .then(async (res) => {
          const challenge_token = res.data.challenge_token;
          const method = res.data.method;
          if (method === 'sign_challenge_token') {
            const deciphered = await decryptECIES(private_key, challenge_token);
            const signature = await signECDSA(private_key, deciphered);
            const dataComplete = {
              challenge_token: deciphered,
              signature: signature.signature,
              public_key,
              address,
              method,
            };
            API.post('/auth/login/wallet/complete', dataComplete, config)
              .then(async (resAuth) => {
                try {
                  mutateUser(await axios.post('/api/login_wallet', resAuth.data.data)).then(() => {
                    setCargando(false);
                    onSubmit();
                    notification('error', '✅ Updated profile');
                  });
                } catch (error) {
                  setCargando(false);
                  notification('error', error.response.data.message);
                }
              })
              .catch((errAuth) => {
                setCargando(false);
                notification('error', errAuth.response.data.message);
              });
          } else {
            const p = new Promise((resolve) => validateAuth(private_key, challenge_token, resolve));
            p.then(async (stacksTransaction) => {
              const transaction = stacksTransaction.serialize().toString('hex');
              const deciphered = await decryptECIES(private_key, challenge_token);
              const signature = await signECDSA(private_key, transaction);
              const dataComplete = {
                public_key,
                address,
                signature: signature.signature,
                transaction,
                challenge_token: deciphered,
                method,
              };
              API.post('/auth/login/wallet/complete', dataComplete, config)
                .then(async (resAuth) => {
                  try {
                    mutateUser(await axios.post('/api/login_wallet', resAuth.data.data)).then(() => {
                      setCargando(false);
                      onSubmit();
                      notification('error', '✅ Updated profile');
                    });
                  } catch (error) {
                    setCargando(false);
                    notification('error', error.response.data.message);
                  }
                })
                .catch((errAuth) => {
                  setCargando(false);
                  notification('error', errAuth.response.data.message);
                });
            }).catch((e) => setCargando(false));
          }
        })
        .catch((errIni) => {
          setCargando(false);
          notification('error', errIni.response.data.message);
        });
    });
  };
  const message2 = "If you haven't created one yet, it only takes a second.";
  const message =
    "In order to claim purchased NFTs and/or confirm your spot on any allowlist, you'll need to connect a wallet to yout account.";
  return (
    <Flex flexDirection="column">
      <Flex alignItems="center" justifyContent="center" flexDirection="column" pt="20px">
        <Flex>
          <Avatar size="xl" name={user?.username || 'U'} w="80px" h="80px" bg="#BC3153" color="#fff" />
        </Flex>
        <Heading fontWeight="bold" fontSize="22px" mt="20px" textAlign="center" textTransform="uppercase">
          Username has been set
        </Heading>
        <Text mt="25px" mb="30px" fontSize="14px" textAlign="center" fontWeight="500">
          {message}
        </Text>
        {device !== 'mobile' && (
          <Button
            isLoading={cargando}
            leftIcon={<Image src="/images/hiro-logo.svg" width="25px" height="18px" alt="" mr="5px" />}
            variant="black"
            w="100%"
            mb="5px"
            h={['48px', '48px', '42px']}
            fontWeight="medium"
            fontSize="14px"
            onClick={onLoginWallet}
          >
            Connect Stacks Wallet
          </Button>
        )}
        <Text mt="15px" fontSize="14px" textAlign="center" fontWeight="500">
          {message2}
        </Text>
        <a href="https://www.youtube.com/watch?v=Bpt1KxtdfZY" target="_blank" rel="noreferrer">
          <Text color="blue.400" fontWeight="bold" fontSize="14px" textDecoration="underline">
            Here are the instructions.
          </Text>
        </a>
        <Text py="20px" fontWeight="bold">
          OR
        </Text>
        <Button w="100%" onClick={onSubmit} fontWeight="medium" fontSize="14px">
          Skipt this step for now
        </Button>
      </Flex>
    </Flex>
  );
};
export default SuccessLink;
