import { useState, useEffect } from 'react';

export default function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (!ref.current) return;

    let options = {
      // root: document.querySelector('#viewport'),
      rootMargin: '600px',
      threshold: 0.5,
    };

    let observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), options);

    // const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}
