import React from 'react';
import Router from 'next/router';
import { Flex, Heading, Icon, Text, Button, Image as ImageChakra } from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';

const ModalErrorToken = ({ error, onClose }) => {
  const onRedirect = () => {
    if (typeof window !== 'undefined') {
      onClose();
      Router.push('/');
    }
  };

  return (
    <>
      <Flex flexDirection="column" alignItems="center" p="20px">
        <Flex alignSelf="flex-end" alignItems="center">
          <Text fontSize={['16px', '16px', '12px']} mr="10px" fontWeight="bold">
            CLOSE
          </Text>
          <Flex cursor="pointer" onClick={onRedirect}>
            <ImageChakra
              src="/images/icon-close.svg"
              alt="close"
              width={['32px', '32px', '24px']}
              height={['32px', '32px', '24px']}
            />
          </Flex>
        </Flex>
        <Flex
          bg="#f6f6f6"
          p="8px 7px 7px 8px"
          w="90px"
          h="90px"
          borderRadius="50%"
          justifyContent="center"
          textAlign="center"
        >
          <Icon as={MdClose} fontSize="55px" color="red" alignSelf="center" />
        </Flex>
        <Heading fontSize="16px" mt="35px" mb="40px" textAlign="center" textTransform="uppercase">
          {error}
        </Heading>
        <Flex py="15px" px="15px" w="100%">
          <Button variant="black" w="100%" onClick={onRedirect}>
            Continue
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default ModalErrorToken;
