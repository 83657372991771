import React, { Fragment, useEffect } from 'react';
import * as yup from 'yup';
import axios from 'axios';
import Router from 'next/router';
import { FiInfo } from 'react-icons/fi';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Alert,
  UnorderedList,
  ListItem,
  useColorMode,
} from '@chakra-ui/react';
import { Flex, Heading, Text, Button, Divider, Icon, Image as ImageChakra } from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { Input, SelectCountry } from 'components/InputForm';
import SuccessLink from './success';
import { countries } from 'config/countries';
import useUser from 'lib/useUser';
import { bgColor, borderColor, colorText } from 'config/data';
import { RiCloseCircleLine } from 'react-icons/ri';

const validationSchema = yup.object().shape({
  username: yup.string().required('Required'),
  // country: yup.string().required('Required'),
});

const ModalAccount = ({ isOpen, onClose, access_token }) => {
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState();

  const { colorMode } = useColorMode();

  const { mutateUser } = useUser();
  // const { mutateUser } = useUser({
  //   redirectTo: '/',
  //   redirectIfFound: true,
  // });

  useEffect(() => {
    setSuccess(false);
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    // setSuccess(true);
    try {
      mutateUser(await axios.post('/api/login', { ...data, access_token })).then(() => {
        // onClose();
        setLoading(false);
        // Router.push('/');
        setSuccess(true);
      });
    } catch (error) {
      setLoading(false);
      // notification('error', error.response.data.message);
      setErrors(error.response.data.errors);
    }
  };

  const onButton = () => {
    if (typeof window !== 'undefined') {
      setSuccess(false);
      onClose();
      Router.push('/');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent
        w={{ base: '90%', md: '400px' }}
        mt={['30px', '30px', '131px']}
        bg={bgColor[colorMode]}
        color={colorText[colorMode]}
      >
        <ModalBody pb="30px" mt="15px" px={['15px', '15px', '25px']}>
          {!success && (
            <Fragment>
              <Flex justifyContent="flex-end" alignItems="center">
                <Text fontSize={['16px', '16px', '12px']} mr="10px" fontWeight="bold">
                  CLOSE
                </Text>
                <Flex cursor="pointer" onClick={onClose}>
                  <Icon as={RiCloseCircleLine} fontSize={['31px', '31px', '23px']} />
                </Flex>
              </Flex>
              <Heading fontSize={['36px', '36px', '24px']} mb="10px">
                COMPLETE ACCOUNT
              </Heading>
              <Divider borderWidth={1} borderColor={borderColor[colorMode]} mb="5px" />
            </Fragment>
          )}
          {success ? (
            <SuccessLink access_token={access_token} onSubmit={onButton} onClose={onClose} />
          ) : (
            <Fragment>
              {errors && (
                <Alert status="error" mt="20px" fontSize="12px" borderRadius="5px" mb="0px">
                  <UnorderedList>{errors && <ListItem> {errors.username[0]}</ListItem>}</UnorderedList>
                </Alert>
              )}
              <Heading fontSize="20px" py="15px" mb="20px">
                Enter your username
              </Heading>

              <Formik
                initialValues={{ username: '', country: '' }}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    onSubmit(values);
                    actions.setSubmitting(false);
                  }, 100);
                }}
              >
                {({ handleSubmit, submitCount, setFieldValue }) => {
                  const onSelect = (values) => {
                    // console.log(values);
                    const find = countries.find((el) => el.name === values.value);
                    setFieldValue('country', find.code);
                  };
                  return (
                    <form onSubmit={handleSubmit}>
                      <Field
                        component={Input}
                        name="username"
                        placeholder="Username"
                        submitCount={submitCount}
                        height="42px"
                      />
                      <Field
                        onSelect={onSelect}
                        component={SelectCountry}
                        isClearable={false}
                        name="country"
                        options={countries.map((i) => ({
                          label: (
                            <Flex alignItems="center">
                              <ImageChakra
                                src={`https://flagcdn.com/24x18/${i.code.toLocaleLowerCase()}.png`}
                                alt=""
                                mr="10px"
                              />
                              <Flex flex="1">
                                <Text>{i.name}</Text>
                              </Flex>
                            </Flex>
                          ),
                          value: i.name,
                        }))}
                        placeholder="Country of Origin (Optional)"
                        submitCount={submitCount}
                        height="42px"
                      />
                      <Flex mt="18px">
                        <Icon as={FiInfo} color="gray.500" fontSize="16px" />
                        <Text fontSize="12px" color="gray.500" ml="10px">
                          If provided, the Country flag will be visible on your profile. You can edit this later.
                        </Text>
                      </Flex>

                      <Button
                        isLoading={loading}
                        fontSize="12px"
                        mt={['19px', '19px', '30px']}
                        w="100%"
                        colorScheme="messenger"
                        h={['48px', '48px', '42px']}
                        type="submit"
                      >
                        Create Account
                      </Button>
                    </form>
                  );
                }}
              </Formik>
            </Fragment>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalAccount;
