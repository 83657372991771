import React, { useEffect, useContext, useRef } from 'react';
import { NextSeo } from 'next-seo';
import { deviceType } from 'react-device-detect';
import axios from 'axios';
import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import ModalAccount from 'components/Modal/Account';
import ModalErrorToken from 'components/Modal/ErrorToken';
import { AuthContext } from 'contexts/auth/auth.context';
import { API } from 'config/api';
import useUser from 'lib/useUser';
import withSession from 'lib/session';
import CompletePurchase from 'components/Modal/CompletePurchase';
import LoadingData from 'components/LoadingData';
import { Collections } from 'components/Collections';
import useSWRInfinite from 'swr/infinite';
import { useDebouncedCallback } from 'use-debounce';
import { useHttp } from 'lib/useHttp';
import useOnScreen from 'hooks/useOnScreen';


const fetcher = (url) => fetch(url).then((res) => res.json());
const PAGE_SIZE = 12;

const getKey = (pageIndex, previousPageData, query) => {
  if (previousPageData && !previousPageData.length) return null; // reached the end
  const result = '&' + new URLSearchParams(query).toString();
  return `/api/collections?page=${pageIndex + 1}${result}`;
};



const IndexPage = ({ access_token, query, error, token, isLoggedIn, pay_bid_token, error_bid, bid_token, user, pathname }) => {

  // Marketplace
  const moreRef = useRef();
  const { data: favorites } = useHttp(`${process.env.NEXT_PUBLIC_API_URL}/me/favorites`, access_token);
  const { data, error: errorData, size, setSize } = useSWRInfinite((...args) => getKey(...args, query), fetcher);

  const isVisible = useOnScreen(moreRef);
  const loadMore = useDebouncedCallback(() => {
    if (isVisible) {
      if (!isReachingEnd) {
        setSize(size + 1);
      }
    }
  }, 500);

  useEffect(() => {
    window.addEventListener('scroll', loadMore);

    return () => {
      window.removeEventListener('scroll', loadMore);
    };
  }, [loadMore]);

  const { user: userData } = useUser();

  const collections = data ? [].concat(...data) : [];
  const isLoadingInitialData = !data && !errorData;
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd = isEmpty || (data && data[data.length - 1]?.length < PAGE_SIZE);


  // Home
  const [device, setDevice] = React.useState('');
  const [visible, setVisible] = React.useState(false);
  const { authDispatch } = useContext(AuthContext);
  const { mutateUser } = useUser(
    isLoggedIn
      ? {}
      : {
        redirectTo: pathname ? pathname : '/',
        redirectIfFound: true,
      }
  );

  useEffect(() => {
    if (pay_bid_token) {
      if (isLoggedIn) {
        // console.log('Show modal');
        setVisible(true);
      } else {
        authDispatch({ type: 'SHOW_MODAL' });
      }
    }
    if (token) {
      let config = { headers: { Authorization: `Bearer ${access_token}` } };
      API.get(`/me`, config)
        .then(async (res) => {
          const user = res.data.data;
          if (user.username) {
            mutateUser(await axios.post('/api/login', { username: user.username, access_token }));
          } else {
            setVisible(true);
          }
        })
        .catch(() => {
          setVisible(true);
        });
    }
    setDevice(deviceType);
  }, [access_token, authDispatch, isLoggedIn, mutateUser, pay_bid_token, setDevice, token]);


  const onButton = () => {
    setVisible(false);
  };

  const propsModal = { user, visible, setVisible, onButton, access_token, error_bid, bid_token, pay_bid_token };

  return (
    <Box id="content">
      <NextSeo
        title="Marketplace"
        description="Try HeyLayer and start your NFT business or grow an existing one. Get more than NFT minting to manage every part of your business."
        canonical="https://marketplace.heylayer.com"
        openGraph={{
          type: 'website',
          url: 'https://marketplace.heylayer.com',
          title: 'Home',
          description:
            'Try HeyLayer and start your NFT business or grow an existing one. Get more than NFT minting to manage every part of your business.',
          locale: 'en_EN',
          images: [
            {
              url: 'https://i.imgur.com/aEsuOty.jpg',
              width: 1200,
              height: 600,
              alt: 'HeyLayer',
            },
          ],
          site_name: 'heylayer.com',
        }}
        twitter={{
          handle: '@HeyLayer',
          site: 'heylayer.com',
          cardType: 'summary_large_image',
        }}
      />




      {isLoadingInitialData ? (
        <LoadingData />
      ) : (
        <>
          <Collections
            showFilters={true}
            favorites={favorites?.data || []}
            data={collections}
            user={userData}
            query={query}
          />
          {/* <Container>
            <Pagination pagination={meta} onPageChange={paginationHandler} />
          </Container> */}
        </>
      )}
      <div id="viewport" ref={moreRef}></div>
      <>
        {isLoadingMore ? (
          <Flex justifyContent="center" py="15px" mb="20px">
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
          </Flex>
        ) : isReachingEnd ? (
          <Flex justifyContent="center" py="15px">
            <Text>No more data</Text>
          </Flex>
        ) : (
          ''
        )}
      </>





      {isLoggedIn && pay_bid_token && <CompletePurchase {...propsModal} />}

      {error ? (
        <ModalErrorToken error={error} isOpen={visible} onClose={() => setVisible(false)} />
      ) : (
        !pay_bid_token && (
          <ModalAccount access_token={access_token} isOpen={visible} onClose={() => setVisible(false)} />
        )
      )}
    </Box>
  );
};

export const getServerSideProps = withSession(async function ({ query, req, res }) {
  const user = req.session.get('user');
  const pathname = req.session.get('pathname');
  const access_token = user?.access_token || '';
  const { auth_token, pay_bid_token } = query;
  let error;
  let error_bid;
  let api_token;
  let bid_token;

  if (user && pay_bid_token) {
    try {
      const config = { headers: { Authorization: `Bearer ${access_token}` } };
      const resBid = await API.get(`/nfts/auction/winning-bid?token=${pay_bid_token}`, config);
      // console.log(resBid.data);
      bid_token = resBid.data;
    } catch (err) {
      // console.log('error', err);
      error_bid = err.response.data.message;
    }
  }

  if (auth_token) {
    try {
      // const res = await API.post('/auth/login/exchange-token', { auth_token });
      // api_token = res.data.data.api_token;
      api_token = query.auth_token;
    } catch (err) {
      error = err.response.data.message;
    }
  }

  return {
    props: {
      token: auth_token || null,
      access_token: api_token || null,
      error: error || null,
      bid_token: bid_token || null,
      error_bid: error_bid || null,
      pay_bid_token: pay_bid_token || null,
      user: user || {},
      isLoggedIn: user?.isLoggedIn || false,
      pathname: pathname || null,
      query
    },
  };
});

export default IndexPage;
