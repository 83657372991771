import React, { Fragment } from 'react';
import { Flex, Text, Icon } from '@chakra-ui/react';
import { MdArrowBack } from 'react-icons/md';
import CheckoutForm from './CheckoutForm';

const Step2 = ({ onSuccess, user, bid_token, pay_bid_token, onBack }) => {
  return (
    <Fragment>
      <Flex py="12px" px="20px" borderBottomWidth={1} alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Icon as={MdArrowBack} onClick={() => onBack(1)} left="20px" cursor="pointer" fontSize="22px" />
          <Text fontWeight="bold" fontSize="14px" ml="10px">
            Back
          </Text>
        </Flex>
      </Flex>
      <Flex px="20px" pt="15px" pb="15px" flexDirection="column">
        <Text fontSize="24px" fontWeight="bold" mb="15px">
          Add Credit Card
        </Text>
        <Text fontSize="12px" mb="23px">
          NFT Checkout
        </Text>

        <CheckoutForm
          pay_bid_token={pay_bid_token}
          onSuccess={onSuccess}
          clientSecret={bid_token?.authorize_net}
          nftData={bid_token?.data}
          user={user}
          access_token={user?.access_token}
        />
      </Flex>
    </Fragment>
  );
};
export default Step2;
