/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import notification from 'components/Notifications';
import {
  Button,
  Flex,
  SimpleGrid,
  FormLabel,
  FormControl,
  Box,
  Alert,
  UnorderedList,
  ListItem,
  Icon,
  Text,
} from '@chakra-ui/react';
import { useAcceptJs } from 'react-acceptjs';
import { nanoid } from 'nanoid';
import { API } from 'config/api';
import Cleave from 'cleave.js/react';
import { FaCcAmex, FaCcDinersClub, FaCcDiscover, FaCcJcb, FaCcMastercard, FaCcVisa } from 'react-icons/fa';
import styled from '@emotion/styled';
import { FiInfo } from 'react-icons/fi';

const authData = {
  apiLoginID: process.env.NEXT_PUBLIC_LOGIN_ID,
  clientKey: process.env.NEXT_PUBLIC_CLIENT_KEY,
};

export const InputCardStyle = styled.div`
  input {
    font-size: 14px;
    padding-left: 1rem;
    padding-right: 1rem;
    height: ${(props) => props.height || '38px'};
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid;
    width: 100%;
    background: ${(props) => props.bg};
    border-color: ${(props) => (props.hasError ? '#e53e3e' : '#ddd')};
  }
  input[type='text']:focus {
    outline: none;
    border-color: #000;
  }
  input::placeholder {
    color: #7f7f7f;
  }
`;

const CheckoutForm = ({ clientSecret, onSuccess, user, nftData, access_token, pay_bid_token }) => {
  const { id, price_usd, name } = nftData;

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [type, setType] = React.useState('');
  const [errors, setErrors] = React.useState();
  const [cardData, setCardData] = useState({
    cardNumber: '',
    month: '',
    year: '',
    cardCode: '',
  });

  const { dispatchData } = useAcceptJs({ environment: process.env.NEXT_PUBLIC_AUTHORIZE_ENV, authData });

  const onSubmit = async (event) => {
    event.preventDefault();
    // Dispatch CC data to Authorize.net and receive payment nonce for use on your server
    cardData.cardNumber = cardData.cardNumber.replace(/ /g, '');
    setLoading(true);
    dispatchData({ cardData })
      .then((res) => {
        setMessages([]);
        // console.log('res', res.opaqueData);
        const config = { headers: { Authorization: `Bearer ${access_token}` } };
        const dataPurchase = {
          currency: 'usd',
          // email: user?.email || '',
          // amount: clientSecret?.amount / 100,
          intent: clientSecret?.intent,
          payment_token: res?.opaqueData?.dataValue,
          token: pay_bid_token,
        };
        // console.log('purchase', dataPurchase);

        API.post('/nfts/auction/winning-bid', dataPurchase, config)
          .then((response) => {
            // console.log(response);
            onSuccess(true);
            setLoading(false);
            window.gtag('event', 'purchase', {
              transaction_id: nanoid(),
              value: clientSecret?.amount / 100,
              currency: 'USD',
              items: [
                {
                  item_id: id,
                  item_name: name,
                  currency: 'USD',
                  price: clientSecret?.amount / 100,
                  quantity: 1,
                },
              ],
            });
          })
          .catch((e) => {
            if (e.response?.data?.errors) {
              setErrors(e.response?.data?.errors || {});
            } else {
              notification('error', `❌${e.response.data.message}`);
            }
            setLoading(false);
          });
      })
      .catch((err) => {
        setMessages(err?.messages?.message);
        setLoading(false);
      });
  };
  const hasErrors = errors ? Object.entries(errors) : {};
  const iconStyle = { pos: 'absolute', right: 3, top: 27, fontSize: 25, color: '#555' };
  return (
    <>
      {!isEmpty(messages) && (
        <Box mb="15px" fontSize="12px">
          <Alert status="error" borderRadius="5px" mb="0px" flexDirection="column" alignItems="flex-start">
            {messages.map((e) => (
              <UnorderedList key={e.code}>
                <ListItem>{e.text}</ListItem>
              </UnorderedList>
            ))}
          </Alert>
        </Box>
      )}
      {!isEmpty(errors) && (
        <Alert status="error" mb="15px" borderRadius={5} fontSize="12px">
          {hasErrors.map((i, idx) => {
            const value = i[1];
            return (
              <Flex flexDirection="column" key={idx}>
                <UnorderedList>
                  {value.map((i, index) => (
                    <ListItem key={index}>{i}</ListItem>
                  ))}
                </UnorderedList>
              </Flex>
            );
          })}
        </Alert>
      )}
      <form onSubmit={onSubmit}>
        <FormControl isInvalid={false} mb="20px">
          <FormLabel mb="3px" fontSize={'13px'} fontWeight="500">
            Card number
          </FormLabel>

          <InputCardStyle hasError={false} height="38px" bg={'#fff'}>
            <Flex pos="revert">
              <Cleave
                options={{
                  creditCard: true,
                  onCreditCardTypeChanged: (type) => setType(type),
                }}
                type="text"
                onChange={(event) => setCardData({ ...cardData, cardNumber: event.target.value })}
                placeholder="0000 0000 0000 0000"
              />
              {type === 'visa' && <Icon as={FaCcVisa} {...iconStyle} />}
              {type === 'mastercard' && <Icon as={FaCcMastercard} {...iconStyle} />}
              {type === 'discover' && <Icon as={FaCcDiscover} {...iconStyle} />}
              {type === 'amex' && <Icon as={FaCcAmex} {...iconStyle} />}
              {type === 'jcb' && <Icon as={FaCcJcb} {...iconStyle} />}
              {type === 'diners' && <Icon as={FaCcDinersClub} {...iconStyle} />}
            </Flex>
          </InputCardStyle>
        </FormControl>

        <SimpleGrid columns={2} spacing="20px">
          <FormControl isInvalid={false} mb="20px">
            <FormLabel mb="3px" fontSize={'13px'} fontWeight="500">
              Expiration month
            </FormLabel>
            <InputCardStyle hasError={false} height="38px" bg={'#fff'}>
              <Flex pos="revert">
                <Cleave
                  options={{
                    date: true,
                    delimiter: '-',
                    datePattern: ['m'],
                  }}
                  type="text"
                  onChange={(event) => setCardData({ ...cardData, month: event.target.value })}
                  placeholder="MM"
                />
              </Flex>
            </InputCardStyle>
          </FormControl>
          <FormControl isInvalid={false} mb="20px">
            <FormLabel mb="3px" fontSize={'13px'} fontWeight="500">
              Expiration year
            </FormLabel>
            <InputCardStyle hasError={false} height="38px" bg={'#fff'}>
              <Flex pos="revert">
                <Cleave
                  options={{
                    date: true,
                    datePattern: ['YY'],
                  }}
                  type="text"
                  onChange={(event) => setCardData({ ...cardData, year: event.target.value })}
                  placeholder="YY"
                />
              </Flex>
            </InputCardStyle>
          </FormControl>
        </SimpleGrid>
        <FormControl isInvalid={false} mb="20px">
          <FormLabel mb="3px" fontSize={'13px'} fontWeight="500">
            Security code
          </FormLabel>
          <InputCardStyle hasError={false} height="38px" bg={'#fff'}>
            <Flex pos="revert">
              <Cleave
                options={{
                  blocks: [4],
                  numericOnly: true,
                }}
                type="text"
                onChange={(event) => setCardData({ ...cardData, cardCode: event.target.value })}
                placeholder="000"
              />
            </Flex>
          </InputCardStyle>
        </FormControl>

        <Flex>
          <Icon as={FiInfo} mr="10px" />
          <Text fontSize="11px" mb="13px" fontWeight="500">
            After purchase you will be able to sync this NFT to your digital wallet. Credit Card payments may take up to
            48 hours to settle and for the NFT to be transferred.
          </Text>
        </Flex>

        <Button w="100%" variant="black" type="submit" disabled={loading} mt="5px" fontWeight="500">
          {loading ? 'Submitting...' : `Pay`}
        </Button>
      </form>
    </>
  );
};

export default CheckoutForm;
