import React, { useEffect, useState } from 'react';
import Router from 'next/router';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react';
// import useUser from 'lib/useUser';
import Error from './error';
import Step1 from './step1';
import Step2 from './step2';
import Success from './success';
import { API } from 'config/api';

const ModalCompletePurchase = ({ visible, setVisible, bid_token, user, pay_bid_token, error_bid }) => {
  // console.log('bid_token:', bid_token);

  const access_token = user?.access_token;

  const [step, setStep] = useState(1);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [intent, setIntent] = useState({});
  const [error, setError] = useState('');

  useEffect(() => {
    const config = { headers: { Authorization: `Bearer ${access_token}` } };
    API.get(`/nfts/auction/winning-bid?token=${pay_bid_token}&currency=microstacks`, config)
      .then((res) => {
        setIntent(res.data.microstacks);
      })
      .catch((e) => setError(e.response.data.message));

    setStep(1);
    setSuccess(false);
  }, [access_token, pay_bid_token]);

  const onClose = () => {
    setStep(1);
    setSuccess(false);
    setVisible(false);
  };

  const onSubmitPay = () => {
    setLoading(true);
    setTimeout(() => {
      setStep(2); // 2 red text
      setLoading(false);
      setSuccess(true);
    }, 1000);
  };

  const onSubmitBtn = () => {
    setLoading(true);
    setTimeout(() => {
      setStep(3);
      setLoading(false);
      setSuccess(true);
    }, 1000);
  };

  const onCloseSuccess = () => {
    if (typeof window !== 'undefined') {
      setStep(1);
      setSuccess(false);
      setVisible(false);
      Router.push('/');
    }
  };

  const onSuccess = () => {
    setSuccess(true);
  };

  const onBack = (step) => {
    setStep(step);
  };

  if (error_bid)
    return (
      <Modal isOpen={visible} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent mt={{ base: '30px', md: '236px' }} w={{ base: '90%', md: '480px' }}>
          <ModalBody p={0}>
            <Error error={error_bid} onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  const step2Props = { user, pay_bid_token, bid_token, access_token, loading, intent };
  return (
    <Modal isOpen={visible} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent mt={{ base: '30px', md: '236px' }} w={{ base: '90%', md: '480px' }}>
        <ModalBody p={0}>
          {step === 1 && <Step1 {...step2Props} onSubmit={onSubmitPay} onClose={onCloseSuccess} />}
          {step === 2 && <Step2 {...step2Props} onSuccess={onSubmitBtn} onBack={onBack} />}
          {step === 3 && <Success onClose={onCloseSuccess} />}

          {/* {success ? (
            <Success onClose={onCloseSuccess} />
          ) : error_bid ? (
            <Error error={error_bid} onClose={onClose} />
          ) : (
            <Step1
              pay_bid_token={pay_bid_token}
              user={user}
              bid_token={bid_token}
              access_token={access_token}
              onSuccess={onSuccess}
            />
          )} */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalCompletePurchase;
