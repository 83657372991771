import React, { useState, useEffect, Fragment } from 'react';
import { Flex, Text, Button, Alert, Image } from '@chakra-ui/react';
import { authenticate, userSession, purchaseNFTLayer } from 'utils/stacks';
import notification from 'components/Notifications';
import { priceStx, priceUsd } from 'utils/utility';
import { deviceType } from 'react-device-detect';
import PurchaseCompleted from './completed';
import { API } from 'config/api';

const Step1 = ({ loading = false, onSubmit, onClose, error, bid_token, intent, access_token, pay_bid_token }) => {
  // console.log('intent', intent);
  // const { data } = bid_token;
  const data = bid_token?.data || {};
  const [isStxPaymentComplete, setIsStxPaymentComplete] = useState(false);
  const [device, setDevice] = useState('');

  useEffect(() => {
    setDevice(deviceType);
  }, []);

  return (
    <Fragment>
      {isStxPaymentComplete ? (
        <PurchaseCompleted onClose={onClose} onSubmit={onClose} />
      ) : (
        <>
          <Flex px="20px" pt="15px" pb="30px" flexDirection="column">
            <Text fontSize="24px" fontWeight="bold">
              Purchase
            </Text>
            <Text fontSize="12px">NFT Checkout</Text>

            {error && (
              <Alert status="error" mt="20px" fontSize="12px" borderRadius="5px">
                {error && <Text> {error}</Text>}
              </Alert>
            )}

            <Flex borderWidth={1} boxShadow="0 10px 20px 0 rgba(0, 0, 0, 0.05)" p="8px" mt="30px" borderRadius={7}>
              <Image
                src={data?.auction?.non_fungible_token?.file?.thumbnail_http_url || '/images/no_image.jpg'}
                alt=""
                w="91px"
                h="91px"
                borderRadius="5px"
                objectFit="cover"
              />
              <Flex ml="15px" flexDirection="column" fontSize="12px" id="content" justifyContent="center">
                <Flex id="name" mb="5px">
                  <Text color="gray.500" w="42px">
                    Name
                  </Text>
                  <Text fontWeight="bold">{data?.auction?.non_fungible_token?.name}</Text>
                </Flex>
                <Flex id="price">
                  <Text color="gray.500" w="42px">
                    Price
                  </Text>
                  <Text fontWeight="bold">
                    {data?.amount_usd && `${priceUsd(data?.amount_usd)} / `}
                    {data?.amount_micro_stx && priceStx(data?.amount_micro_stx)}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex px="20px" pb="14px" flexDirection="column">
            <Button
              width="100%"
              isLoading={loading}
              type="submit"
              height="40px"
              mt="12px"
              variant="black"
              _disabled={{ background: '#7f7f7f', color: 'white' }}
              fontSize="14px"
              fontWeight="500"
              _hover={{ bg: '#7f7f7f' }}
              onClick={onSubmit}
            >
              Pay USD by Credit Card
            </Button>
            {device !== 'mobile' && (
              <Button
                variant="outline"
                mt="15px"
                fontSize="14px"
                borderColor="black"
                onClick={() => {
                  const config = { headers: { Authorization: `Bearer ${access_token}` } };
                  const amount = intent.amount; // intent.amount
                  const memo = intent.memo; // intent.memo
                  if (userSession.isUserSignedIn()) {
                    const p = new Promise((resolve, reject) => purchaseNFTLayer(amount, memo, resolve));
                    p.then((txId) => {
                      const dataPurchase = {
                        token: pay_bid_token,
                        intent: intent?.token,
                        transaction_id: txId,
                        currency: 'microstacks',
                      };
                      API.post('/nfts/auction/winning-bid', dataPurchase, config)
                        .then(() => {
                          // onSuccess(true);
                          setIsStxPaymentComplete(true);
                        })
                        .catch((err) => console.log(err));
                      // .catch((err) => notification('error', `❌${err.response.data.message}`));
                    });
                  } else {
                    authenticate(() => {
                      const p = new Promise((resolve, reject) => purchaseNFTLayer(amount, memo, resolve));
                      p.then((txId) => {
                        const dataPurchase = {
                          token: pay_bid_token,
                          intent: intent?.token,
                          transaction_id: txId,
                          currency: 'microstacks',
                        };
                        API.post('/nfts/auction/winning-bid', dataPurchase, config)
                          .then(() => {
                            // onSuccess(true);
                            setIsStxPaymentComplete(true);
                          })
                          .catch((err) => console.log(err));
                        // .catch((err) => notification('error', `❌${err.response.data.message}`));
                      });
                    });
                  }
                }}
              >
                Pay with STX
              </Button>
            )}
          </Flex>
        </>
      )}
    </Fragment>
  );
};
export default Step1;
