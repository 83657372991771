import React from 'react';
import { Box, SimpleGrid, SkeletonCircle, Skeleton } from '@chakra-ui/react';
import { Container } from 'components/Container';

const LoadingData = ({ rows }) => {
  const total = new Array(rows || 12).fill();
  return (
    <Box as="section" id="loading" py={{ base: '5px', md: '60px' }} px={['15px', '10px', 0]}>
      <Container>
        <Box id="collections_content">
          <SimpleGrid id="grid" columns={[1, 1, 4]} spacing="40px">
            {total.map((i, idx) => (
              <Box key={idx}>
                <SkeletonCircle size={{ base: '345', md: '301px' }} borderRadius={0} />
                <Skeleton height="12px" mt="25px" mb="15px" />
                <Skeleton height="12px" my="10px" />
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </Container>
    </Box>
  );
};
export default LoadingData;
